/**
 * Create a stacking context on the root so that our portal overlays always
 * appear above the app. If you don't know what I mean by "portal", find the
 * <Portal /> component in the UI library. Relevant background:
 *
 * https://github.com/clipchamp/clipchamp-stack/pull/2652
 * https://go.clipchamp.com/mdn-stacking-context
 *
 * Relevant quote from MDN:
 *
 *     > A stacking context is formed, anywhere in the document, by any element
 *     > in the following scenarios:
 *     >
 *     >     - Element with a position value absolute or relative and z-index
 *     >       value other than auto.
 */
#root {
    position: relative;
    z-index: 0;
}

.ab-in-app-message .ab-message-text {
    font-family: 'Segoe UI', system-ui, sans-serif;
}

body {
    color-scheme: light dark; /* Set up color scheme, uses device/browser setting */
    background: light-dark(#f0f0f5, #090911); /* --colorNeutralSurface3 */
}

/* REGULAR 400 */
@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-greek.woff) format('woff');
    unicode-range: U+0370-03FF, U+1F00-1FFF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-cyrillic.woff) format('woff');
    unicode-range: U+0400-04FF, U+0500-052F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-rest.woff) format('woff');
    unicode-range:
        U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121, U+2123-218F, U+21BC-2211,
        U+2213-2214, U+2216-F8FE, U+FB01-FB02, U+1DC0-1DCA;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-latin-extended-additional.woff)
        format('woff');
    unicode-range: U+1E00-1EFF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-latin-extended-b.woff) format('woff');
    unicode-range: U+0180-024F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-latin-extended-a.woff) format('woff');
    unicode-range: U+0100-0130, U+0132-0151, U+0154-017F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-rest-latin.woff) format('woff');
    unicode-range:
        U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6, U+00D8-00F6, U+00F8-00FF, U+0131,
        U+0152-0153, U+02B0-02FF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-regular-english.woff) format('woff');
    unicode-range:
        U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA, U+00BC-00BE, U+00D7,
        U+00F7, U+2000-206F, U+2074, U+20AC, U+2122, U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF,
        U+FFFD;
}

/* SEMIBOLD 600 */
@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-greek.woff) format('woff');
    unicode-range: U+0370-03FF, U+1F00-1FFF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-cyrillic.woff) format('woff');
    unicode-range: U+0400-04FF, U+0500-052F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-rest.woff) format('woff');
    unicode-range:
        U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121, U+2123-218F, U+21BC-2211,
        U+2213-2214, U+2216-F8FE, U+FB01-FB02, U+1DC0-1DCA;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-latin-extended-additional.woff)
        format('woff');
    unicode-range: U+1E00-1EFF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-latin-extended-b.woff) format('woff');
    unicode-range: U+0180-024F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-latin-extended-a.woff) format('woff');
    unicode-range: U+0100-0130, U+0132-0151, U+0154-017F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-rest-latin.woff) format('woff');
    unicode-range:
        U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6, U+00D8-00F6, U+00F8-00FF, U+0131,
        U+0152-0153, U+02B0-02FF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-semibold-english.woff) format('woff');
    unicode-range:
        U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA, U+00BC-00BE, U+00D7,
        U+00F7, U+2000-206F, U+2074, U+20AC, U+2122, U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF,
        U+FFFD;
}

/* BOLD 700 */
@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-greek.woff) format('woff');
    unicode-range: U+0370-03FF, U+1F00-1FFF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-cyrillic.woff) format('woff');
    unicode-range: U+0400-04FF, U+0500-052F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-rest.woff) format('woff');
    unicode-range:
        U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121, U+2123-218F, U+21BC-2211,
        U+2213-2214, U+2216-F8FE, U+FB01-FB02, U+1DC0-1DCA;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-latin-extended-additional.woff)
        format('woff');
    unicode-range: U+1E00-1EFF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-latin-extended-b.woff) format('woff');
    unicode-range: U+0180-024F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-latin-extended-a.woff) format('woff');
    unicode-range: U+0100-0130, U+0132-0151, U+0154-017F;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-rest-latin.woff) format('woff');
    unicode-range:
        U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6, U+00D8-00F6, U+00F8-00FF, U+0131,
        U+0152-0153, U+02B0-02FF;
}

@font-face {
    font-family: 'Segoe UI';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/segoe-ui-static/segoe-ui-static-bold-english.woff) format('woff');
    unicode-range:
        U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA, U+00BC-00BE, U+00D7,
        U+00F7, U+2000-206F, U+2074, U+20AC, U+2122, U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF,
        U+FFFD;
}

/* VARIABLE */
@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-greek.woff2) format('woff2');
    unicode-range: U+0370-03FF, U+1F00-1FFF;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-cyrillic.woff2) format('woff2');
    unicode-range: U+0400-04FF, U+0500-052F;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-rest.woff2) format('woff2');
    unicode-range:
        U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121, U+2123-218F, U+21BC-2211,
        U+2213-2214, U+2216-F8FE, U+FB01-FB02, U+1DC0-1DCA;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-latin-extended-additional.woff2) format('woff2');
    unicode-range: U+1E00-1EFF;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-latin-extended-b.woff2) format('woff2');
    unicode-range: U+0180-024F;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-latin-extended-a.woff2) format('woff2');
    unicode-range: U+0100-0130, U+0132-0151, U+0154-017F;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-rest-latin.woff2) format('woff2');
    unicode-range:
        U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6, U+00D8-00F6, U+00F8-00FF, U+0131,
        U+0152-0153, U+02B0-02FF;
}

@font-face {
    font-family: 'Segoe UI Variable';
    font-display: swap;
    font-style: normal;
    font-weight: 300 700;
    src: url(./fonts/segoe-ui-vf/segoe-ui-vf-english.woff2) format('woff2');
    unicode-range:
        U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA, U+00BC-00BE, U+00D7,
        U+00F7, U+2000-206F, U+2074, U+20AC, U+2122, U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF,
        U+FFFD;
}

* {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-optical-sizing: auto;
}

html {
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 100%;
    font-weight: 400;
    overscroll-behavior-y: none;
}

@supports (font-variation-settings: normal) {
    body {
        font-family: 'Segoe UI Variable', 'Segoe UI', system-ui, sans-serif;
    }
}

button,
input,
textarea {
    font-family: inherit;
}

body:not(.navigating-via-keyboard) :focus {
    outline: inherit;
}

input,
textarea,
button {
    font-family: inherit;
}
